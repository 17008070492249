<template>
  <div>
    <index-filter :cols="[3,3]" class="mb-3">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
    </index-filter>

    <v-card>
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="paginate"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>
      </v-card-text>

      <registration-table
        ref="tableRef"
        :table-list="tableList"
      />
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { IndexFilter, SearchFilter } from '@/components'
import { useTableList } from '@/composables'
import { mdiRefresh } from '@mdi/js'
import RegistrationTable from '../components/list/RegistrationTable.vue'
import { registrationService } from '../services'

export default {
  components: {
    IndexFilter,
    SearchFilter,

    RegistrationTable,
  },

  setup(props) {
    const filters = ref({
      search: null,
    })

    const tableList = useTableList(registrationService, filters)

    const { loading, tableRef, paginate } = tableList

    return {
      filters,

      loading,
      tableRef,
      paginate,
      tableList,

      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>

  <style lang="scss" scoped></style>
